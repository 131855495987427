import {lazy} from 'react'

const routes = [
    {
        path: '/invite',
        component: lazy(() => import('../pages/InviteNewMembers/index')),
        meta: {
            title: '邀请'
        }
    },
    {
        path: '/jurisdiction',
        component: lazy(() => import('../pages/InviteNewMembers/indexDele')),
        meta: {
            title: '权限异常'
        }
    },
    {
        path: '/bim',
        component: lazy(() => import('../pages/BimMenu/index')),
        meta: {
            title: '主菜单'
        },
        routes: [
            {
                path: "/bim/message",
                component: lazy(() => import('../pages/BimView/Message/index')),
                meta: {
                    title: '代办列表'
                }
            },
            {
                path: '/bim/myProject',
                component: lazy(() => import('../pages/BimView/MyProject/index')),
                meta: {
                    title: '我的项目'
                }
            },
            {
                path: '/bim/myProjectGroup/:id/:name',
                component: lazy(() => import('../pages/BimView/MyProject/group/index')),
                meta: {
                    title: '我的项目-分组'
                }
            },
            {
                path: '/bim/modelingRules',
                component: lazy(() => import('../pages/BimView/ModelingRules/index')),
                meta: {
                    title: '建模规则'
                }
            },
            {
                path: '/bim/teamMembers',
                component: lazy(() => import('../pages/BimView/TeamMembers/index')),
                meta: {
                    title: '团队成员'
                }
            },
            {
                path: '/bim/roleManagement',
                component: lazy(() => import('../pages/BimView/RoleManagement/index')),
                meta: {
                    title: '角色管理'
                }
            },
            {
                path: '/bim/quota',
                component: lazy(() => import('../pages/BimView/EnterpriseQuota/quota/index')),
                meta: {
                    title: '企业工序成本'
                }
            },
            {
                path: '/bim/tenantConfig',
                component: lazy(() => import('../pages/BimView/Config/TeamInfo/index')),
                meta: {
                    title: '团队信息'
                }
            },
            {
                path: '/bim/materialType',
                component: lazy(() => import('../pages/BimView/Config/ProjectManage/materialType/index')),
                meta: {
                    title: '材料类别'
                }
            },
            {
                path: '/bim/materialPrice',
                component: lazy(() => import('../pages/BimView/Config/ProjectManage/materialPrice/index')),
                meta: {
                    title: '材料价格'
                }
            },
            {
                path: '/bim/purchase/supplier',
                component: lazy(() => import('../pages/ErpView/Purchase/Supplier/index')),
                meta: {
                    title: '材料供应商'
                }
            },
            {
                path: '/bim/processConfiguration',
                component: lazy(() => import('../pages/BimView/Config/ProcessConfiguration/index')),
                meta: {
                    title: 'bpmn'
                }
            },
            {
                path: '/bim/teamPermissions',
                component: lazy(() => import('../pages/BimView/Config/TeamPermissions/index')),
                meta: {
                    title: '团队权限'
                }
            },
            {
                path: '/bim/projectPermissions',
                component: lazy(() => import('../pages/BimView/Config/ProjectPermissions/index')),
                meta: {
                    title: '项目权限'
                }
            },
            {
                path: "/bim/demo",
                component: lazy(() => import('../pages/BimView/Demo/index'))
            },
        ]
    },
    {
        path: '/project',
        component: lazy(() => import('../pages/BimMenu/index')),
        meta: {
            title: '我的项目菜单'
        },
        // 若有子页面，此为参考
        routes: [
            {
                path: '/project/buildingModel',
                component: lazy(() => import('../pages/ProjectView/BuildingModel/index')),
                meta: {
                    title: '区域划分'
                }
            },
            {
                path: '/project/roomModel',
                component: lazy(() => import('../pages/ProjectView/RoomModel/index')),
                meta: {
                    title: '区域清单'
                }
            },
            {
                path: '/project/summary',
                component: lazy(() => import('../pages/ProjectView/Summary/index')),
                meta: {
                    title: '工程量清单'
                }
            },
            {
                path: '/project/teamMembers',
                component: lazy(() => import('../pages/ProjectView/TeamMembers/index')),
                meta: {
                    title: '项目成员'
                }
            },
            {
                path: '/project/weeklyPlan',
                component: lazy(() => import('../pages/ProjectView/WeeklyPlan/index')),
                meta: {
                    title: '周计划'
                }
            },
            {
                path: '/project/taskAssign',
                component: lazy(() => import('../pages/ProjectView/TaskAssign/index')),
                meta: {
                    title: '分配任务'
                }
            },
            {
                path: '/project/quota',
                component: lazy(() => import('../pages/BimView/EnterpriseQuota/quota/index')),
                meta: {
                    title: '项目工序成本'
                }
            },
            {
                path: '/project/quotaMatch',
                component: lazy(() => import('../pages/ProjectView/ProjectQuota/quotaMatch/index')),
                meta: {
                    title: '工序成本计算'
                }
            },
            {
                path: '/project/exchangeRate',
                component: lazy(() => import('../pages/ProjectView/ExchangeRate/index')),
                meta: {
                    title: '汇率设置'
                }
            },
            {
                path: '/project/taskKanBan',
                component: lazy(() => import('../pages/ProjectView/TaskKanBan/index')),
                meta: {
                    title: '任务看板'
                }
            },
            {
                path: '/project/imageRecord',
                component: lazy(() => import('../pages/ProjectView/ImageRecord/index')),
                meta: {
                    title: '周计划'
                }
            },
            {
                path: '/project/materialScience',
                component: lazy(() => import('../pages/ProjectView/MaterialScience/index')),
                meta: {
                    title: '材料'
                }
            },
            {
                path: '/project/artificial',
                component: lazy(() => import('../pages/ProjectView/Artificial/index')),
                meta: {
                    title: '人工'
                }
            },
            {
                path: '/project/costSummary',
                component: lazy(() => import('../pages/ProjectView/CostSummary/index')),
                meta: {
                    title: '成本汇总'
                }
            },
            {
                path: '/project/laborInfo',
                component: lazy(() => import('../pages/ProjectView/LaborManage/laborInfo/index')),
                meta: {
                    title: '劳动力信息'
                }
            },
            {
                path: '/project/rolePermission',
                component: lazy(() => import('../pages/ProjectView/RolePermission/index')),
                meta: {
                    title: '项目角色'
                }
            },
            {
                path: '/project/purchase/request',
                component: lazy(() => import('../pages/ErpView/Purchase/Request/index')),
                meta: {
                    title: '采购申请'
                }
            },
            {
                path: '/project/purchase/contract',
                component: lazy(() => import('../pages/ErpView/Purchase/Contract/index')),
                meta: {
                    title: '采购合同'
                }
            },
            {
                path: '/project/purchase/contractChange',
                component: lazy(() => import('../pages/ErpView/Purchase/ContractChange/index')),
                meta: {
                    title: '合同修订'
                }
            },
            {
                path: '/project/purchase/split',
                component: lazy(() => import('../pages/ErpView/Purchase/Split/index')),
                meta: {
                    title: '分项请采'
                }
            },
            {
                path: '/project/purchase/arrival',
                component: lazy(() => import('../pages/ErpView/Purchase/Arrival/index')),
                meta: {
                    title: '到货单'
                }
            },
            {
                path: '/project/purchase/payment',
                component: lazy(() => import('../pages/ErpView/Purchase/Payment/index')),
                meta: {
                    title: '付款申请'
                }
            },
            {
                path: '/project/contract/change',
                component: lazy(() => import('../pages/ErpView/Contract/Change/index')),
                meta: {
                    title: '变更洽商'
                }
            },
        ]
    },
    {
        path: '/forgetPassword',
        component: lazy(() => import('../pages/UserView/ForgetPassword')),
        meta: {
            title: '忘记密码'
        }
    },
    {
        path: '/dingtalkBind/:source/:oauthId',
        component: lazy(() => import('../pages/UserView/DingtalkBind')),
        meta: {
            title: '钉钉绑定'
        }
    },
    {
        path: '/',
        component: lazy(() => import('../pages/HomeView/Home')),
        meta: {
            title: '首页'
        }
    },
    {
        path: "/genCode",
        component: lazy(() => import('../pages/ErpView/GenCode/index')),
        meta: {
            title: 'erp代码生成器'
        }
    },
    {
        path: '/app',
        component: lazy(() => import('../pages/App/index')),
        meta: {
            title: 'App管理'
        }
    },
]

export default routes
