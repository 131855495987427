import type {MouseEvent} from 'react';
import {Component} from 'react';
import type {ModalProps, ModalFuncProps} from 'antd/lib/modal';
import ReactDOM from 'react-dom';
import React, {forwardRef} from 'react';
import {Modal as AntdModal} from 'antd';

/**
 * 可拖动的Modal弹窗
 * 继承antd Modal弹窗的功能进行二次封装
 */
export default class AntDraggableModal extends Component<ModalProps> {
    private simpleClass: string;
    private header: any;
    private footer: any;
    private contain: any;
    private modalContent: any;

    private mouseDownX: number = 0;
    private mouseDownY: number = 0;
    private deltaX: number = 0;
    private deltaY: number = 0;
    private sumX: number = 0;
    private sumY: number = 0;
    private offsetLeft: number = 0;
    private offsetTop: number = 0;

    constructor(props: ModalProps) {
        super(props);
        this.simpleClass = Math.random().toString(36).substring(2);
    }

    // 鼠标拖动-动态设置弹窗位置
    handleMove = (event: any) => {
        const deltaX = event.pageX - this.mouseDownX;
        const deltaY = event.pageY - this.mouseDownY;
        this.deltaX = deltaX;
        this.deltaY = deltaY;
        let tranX = deltaX + this.sumX;
        let tranY = deltaY + this.sumY;
        // // 左侧
        // if (tranX < -this.offsetLeft) {
        //     tranX = -this.offsetLeft;
        // }
        // // 右侧
        // const offsetRight =
        //     document.body.clientWidth -
        //     this.modalContent.parentElement.offsetWidth -
        //     this.offsetLeft;
        // if (tranX > offsetRight) {
        //     tranX = offsetRight;
        // }
        // // 上侧
        // if (tranY < -this.offsetTop) {
        //     tranY = -this.offsetTop;
        // }
        // // 下侧
        // const offsetBottom =
        //     document.body.clientHeight -
        //     this.modalContent.parentElement.offsetHeight -
        //     this.offsetTop;
        // if (tranY > offsetBottom) {
        //     tranY = offsetBottom;
        // }
        this.modalContent.style = `top:${tranY}px;left:${tranX}px`;
        // this.modalContent.style.transform = `translate(${tranX}px, ${tranY}px)`;
    };

    initialEvent = (visible: boolean) => {
        const {title} = this.props;
        if (title && visible) {
            setTimeout(() => {
                window.removeEventListener('mouseup', this.removeUp, false);
                this.contain = document.getElementsByClassName(this.simpleClass)[0];
                this.header = this.contain.getElementsByClassName('ant-modal-header')[0];
                this.modalContent = this.contain.getElementsByClassName('ant-modal-content')[0];
                this.footer = this.contain.getElementsByClassName('ant-modal-footer')[0];
                this.offsetLeft = this.modalContent.parentElement.offsetLeft;
                this.offsetTop = this.modalContent.parentElement.offsetTop;
                this.header.style.cursor = 'all-scroll';
                this.header.onmousedown = (e: MouseEvent<HTMLDivElement>) => {
                    this.mouseDownX = e.pageX;
                    this.mouseDownY = e.pageY;
                    document.body.onselectstart = () => false;
                    window.addEventListener('mousemove', this.handleMove, false);
                };
                if (this.footer) {
                    this.footer.style.cursor = 'all-scroll';
                    this.footer.onmousedown = (e: MouseEvent<HTMLDivElement>) => {
                        this.mouseDownX = e.pageX;
                        this.mouseDownY = e.pageY;
                        document.body.onselectstart = () => false;
                        window.addEventListener('mousemove', this.handleMove, false);
                    };
                }
                window.addEventListener('mouseup', this.removeUp, false);
                window.addEventListener('resize', this.handleResize, false);
            }, 0);
        }
    };

    // 窗口变化
    handleResize = () => {
        this.offsetLeft = this.modalContent.parentElement.offsetLeft;
        this.offsetTop = this.modalContent.parentElement.offsetTop;
    }

    // 移除监听（鼠标移动事件）
    removeMove = () => {
        window.removeEventListener('mousemove', this.handleMove, false);
    };

    // 监听鼠标移动事件
    removeUp = () => {
        document.body.onselectstart = () => true;
        this.sumX += this.deltaX;
        this.sumY += this.deltaY;
        this.deltaX = 0;
        this.deltaY = 0;
        // if (this.sumX < -this.offsetLeft) {
        //     this.sumX = -this.offsetLeft;
        // }
        // const offsetRight =
        //     document.body.clientWidth -
        //     this.modalContent.parentElement.offsetWidth -
        //     this.offsetLeft;
        // if (this.sumX > offsetRight) {
        //     this.sumX = offsetRight;
        // }
        // // 上侧
        // if (this.sumY < -this.offsetTop) {
        //     this.sumY = -this.offsetTop;
        // }
        // // 下侧
        // const offsetBottom =
        //     document.body.clientHeight -
        //     this.modalContent.parentElement.offsetHeight -
        //     this.offsetTop;
        // if (this.sumY > offsetBottom) {
        //     this.sumY = offsetBottom;
        // }
        this.removeMove();
    };

    // 关闭后重新打开弹窗-重置弹窗位置
    resetModalPosition = () => {
        this.mouseDownX = 0;
        this.mouseDownY = 0;
        this.deltaX = 0;
        this.deltaY = 0;
        this.sumX = 0;
        this.sumY = 0;
        this.offsetLeft = 0;
        this.offsetTop = 0;
        this.modalContent && (this.modalContent.style = `top:0px;left:0px`);
    }

    componentDidMount() {
        const {visible = false, open = false} = this.props;
        this.initialEvent(visible || open);
    }

    componentWillReceiveProps(newProps: any) {
        const visible = this.props.visible || this.props.open;
        const visibleNew = newProps.visible || newProps.open;

        if (visibleNew && !visible) {
            this.initialEvent(visibleNew);
            this.resetModalPosition();
        }
        if (visible && !visibleNew) {
            this.removeMove();
            window.removeEventListener('mouseup', this.removeUp, false);
        }
    }

    componentWillUnmount() {
        this.removeMove();
        window.removeEventListener('mouseup', this.removeUp, false);
        window.removeEventListener('resize', this.handleResize, false);
    }

    render() {
        const {children, wrapClassName, ...other} = this.props;
        const wrapModalClassName = wrapClassName
            ? `${wrapClassName} ${this.simpleClass}`
            : `${this.simpleClass}`;
        return (
            <AntdModal maskClosable={false} {...other} wrapClassName={wrapModalClassName}>
                {children}
            </AntdModal>
        );
    }
}

// 函数弹窗
const Modal = (props: any) => {
    const {visible, open, children, ...rest} = props;
    return (<AntDraggableModal
        maskClosable={false}
        {...rest}
        open={visible || open}
    >
        {
            typeof children === 'object' ? React.Children.map(children, child => React.cloneElement(child)) : children
        }
    </AntDraggableModal>)
}

const ModalFun: any = forwardRef(Modal);
export {
    ModalFun
};

ModalFun.confirm = (props: ModalFuncProps) => {
    const {title, content, ...rest} = props;
    let element: any = document.createElement('div');
    document.body.appendChild(element);
    const onClose = () => {
        try {
            ReactDOM.render(getModalNode({visible: false}), element)
            if (props.onCancel && typeof props.onCancel === 'function') {
                props.onCancel();
            }
        } catch (error) {

        }
    }
    const afterClose = () => {
        ReactDOM.unmountComponentAtNode(element);
        element.remove();
        element = null;
        if (props.afterClose && typeof props.afterClose === 'function') {
            props.afterClose();
        }
    }
    const onOk = () => {
        if (props.onOk && typeof props.onOk === 'function') {
            const maybePromise = props.onOk();
            if (maybePromise instanceof Promise) {
                maybePromise
                    .then(() => {
                        onClose();
                    })
            } else {
                onClose();
            }
            return;
        }
        onClose();
    }
    const getModalNode = ({visible}: any) => {
        let modalNode = null;
        modalNode = (
            <Modal
                {...rest}
                visible={visible}
                title={title}
                afterClose={afterClose}
                onOk={onOk}
                onCancel={onClose}
                wrapClassName="modal-NBborder"
                maskClosable={false}
            >
                {content}
            </Modal>
        )
        return modalNode;
    }
    ReactDOM.render(getModalNode({visible: true}), element)
    return {
        hide: onClose
    }
}